<template>
  <div>
    <v-app-bar color="grey lighten-4" app>
      <v-img
        class="mx-2"
        src="img/indosur.png"
        max-height="100"
        max-width="100"
        contain
      ></v-img>
      <v-btn text color="teal" to="recipes"> Recetas </v-btn>
      <v-btn text color="teal" to="closings"> Cierres </v-btn>
      <v-btn text color="teal" to="account"> Cuenta </v-btn>
      <v-btn text color="teal" to="sales"> Ventas </v-btn>
      <v-spacer />
      <ShoppingCart />
      <v-btn text color="teal" to="/"> Inicio </v-btn>
      <SessionComponent />
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-btn
      style="position: fixed"
      color="teal"
      x-large
      absolute
      bottom
      left
      rounded
      dark
      :loading="loading"
      @click="downloadInstructive()"
    >
      Descargar instructivo de uso
      <v-icon class="ml-2" x-large> mdi-download-circle </v-icon>
    </v-btn>

    <SnackBarComponent />
  </div>
</template>

<script>
import ShoppingCart from "../components/shoppingCart/ShoppingCartComponent.vue";
import SessionComponent from "../components/shared/SessionComponent.vue";
import SnackBarComponent from "../components/shared/SnackBarAlertComponent.vue";

import { downloadInstructive } from "../services/utils.js";

export default {
  components: {
    SessionComponent,
    SnackBarComponent,
    ShoppingCart,
  },

  data: () => ({
    loading: false,
  }),
  methods: {
    async downloadInstructive() {
      this.loading = true;
      await downloadInstructive();
      this.loading = false;
    },
  },
};
</script>
