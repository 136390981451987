<template>
  <v-menu
    bottom
    left
    offset-y
    :close-on-content-click="false"
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="shoppingCart.length"
        :value="shoppingCart.length"
        color="red"
        left
      >
        <v-btn small color="teal" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <ShoppingCartCard />
  </v-menu>
</template>
<script>
import ShoppingCartCard from "./ShoppingCartCardComponent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ShoppingCard",
  components: {
    ShoppingCartCard,
  },

  created() {
    this.loadStoredShoppingCart();
  },

  methods: {
    ...mapActions("shoppingCart", ["loadStoredShoppingCart"]),
  },

  computed: {
    ...mapGetters("shoppingCart", ["shoppingCart"]),
  },
};
</script>

